import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import { CollectionsMSBWidgetWidgetData } from './config/types';

const DEFAULT_DATA = {
  collections: [],
  collectionProjects: [],
} as CollectionsMSBWidgetWidgetData;

export default createBlocksModel({
  widgetName: 'collectionMSBWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Collection MSB Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for Collection MSB Widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    change: {
      title: 'Custom Text Fields Changed',
      description: '',
    },
  },
});
