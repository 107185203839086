import model from './model';
import { CollectionsListWidgetComponentIds as ComponentIds } from './config/constants';
import { CollectionsListWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const {$w, $widget} = controllerParams;
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
