import model from './model';
import { ProjectHeaderWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectHeaderWidgetProps as Props } from './config/types';
import {
  Image,
  Video,
} from '@wix/ambassador-portfolio-projects-v1-project/types';
import { convertCoverToWixCode } from '../../converters/mediaConverter';

const getWixCodePGCover = (coverItem: Image | Video) => {
  const item = convertCoverToWixCode(coverItem);
  return [item];
};

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    description: $w(ComponentIds.Description),
    details: $w(ComponentIds.Details),
    galleryCover: $w(ComponentIds.GalleyCover),
  });

  const initByProps = async (props: Props) => {
    const { data } = props;
    const { title, details, description, coverItem } = data;
    components.title.text = title;
    components.details.data = { projectDetails: details };
    components.description.text = description;
    components.galleryCover.items = getWixCodePGCover(coverItem);
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    const isWidgetOnStage = !!components.topContainer.id;
    if (isWidgetOnStage) {
      initByProps(nextProps);
    }
  });

  return {
    pageReady: async () => {},
    exports: {},
  };
});
