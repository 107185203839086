import _ from 'lodash';
import { Pages, PortfolioRoutersPrefix } from '../editor-app/first-install/const/pagesConsts';

export const COLLECTION_SLUG_PATH_INDEX = 0;
export const PROJECT_SLUG_PATH_INDEX = 1;

export const getBaseUrl = (flowAPI) => {
  return `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}`;
};

export const getPortfolioBaseUrl = (flowAPI) => {
  let portfolioPagePath;
  try {
    const appRouters = flowAPI.controllerConfig.appParams.appRouters;
    const wixPortfolioPagesData = JSON.parse(
      appRouters[0]?.config,
    )?.portfolioPagesData;
    const portfolioPageData = _.find(
      wixPortfolioPagesData,
      (page) => page.tpaPageId === Pages.PORTFOLIO_PAGE.pageId,
    );
    portfolioPagePath = `/${portfolioPageData.pageUriSEO || ''}`;
  } catch {
    portfolioPagePath = '/';
  }
  return `${portfolioPagePath}`;
};
export const getPortfolioRouterBaseUrl = (flowAPI) => {
  const [appRouter]: any = flowAPI.controllerConfig.appParams.appRouters;
  return `${getBaseUrl(flowAPI)}/${appRouter.prefix}`;
};

export const getCollectionUrl = (flowAPI, collectionSlug) => {
  const [appRouter]: any = flowAPI.controllerConfig.appParams.appRouters;
  const prefix = appRouter?.prefix || PortfolioRoutersPrefix.PORTFOLIO_COLLECTIONS;
  return `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}/${prefix}/${collectionSlug}`;
};

export const getProjectUrl = (flowAPI, collectionSlug, projectSlug) => {
  return `${getCollectionUrl(flowAPI, collectionSlug)}/${projectSlug}`;
};
export const getUrlNextNPrevProject = (
  flowAPI,
  collectionSlug,
  projectSlug,
) => {
  const [appRouter]: any = flowAPI.controllerConfig.appParams.appRouters;
  return `/${appRouter.prefix}/${collectionSlug}/${projectSlug}`;
};

export const getCollectionSlugFromUrl = (flowAPI) => {
  const routerData =
    flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData();
  const collectionSlug = routerData?.collectionPageData?.slug;
  const collectionSlugFromUrl =
    flowAPI.controllerConfig.wixCodeApi.location.path[
      COLLECTION_SLUG_PATH_INDEX
    ];
  return collectionSlug || collectionSlugFromUrl;
};

export const getProjectSlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[
    PROJECT_SLUG_PATH_INDEX
  ];
};
