import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';

export enum PortfolioPageWidgetComponentIds {
  Root = '#portfolioPageWidgetRoot',
  MultiStateWidget = '#collectionMsbWidget1',
}

export enum PortfolioPageWidgetEvents {}

export const PortfolioPageWidgetTabsArray: widgetDesignTabsBuilderArray = [
  {
    label: 'Portfolio_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Portfolio_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Portfolio_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Portfolio_Design_Panel_Page_Background',
    roles: [PortfolioPageWidgetComponentIds.Root],
  },
];

export const PortfolioPageWidgetPresets: WidgetPresetsArray = [
  {
    id: 'externalPreset-variants-lcx0pjfc',
    connectedMobilePresetID: 'variants-lcx0z393',
    src: 'https://static.wixstatic.com/media/8bb438_e835ecc504214e77982a57b8e4789b22~mv2.png',
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lcx10cj9',
    connectedMobilePresetID: 'variants-lcx11hw0',
    src: 'https://static.wixstatic.com/media/8bb438_5f28cc80588f4d5082b3da15d8e258ca~mv2.png',
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lcx4eft0',
    connectedMobilePresetID: 'variants-lcx4fuow',
    src: 'https://static.wixstatic.com/media/8bb438_99d0e73dafd9497e87a8352691fb6765~mv2.png',
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lcx12n3p',
    connectedMobilePresetID: 'variants-lcx2hg5i',
    src: 'https://static.wixstatic.com/media/8bb438_1bdb975eb3b1420ea01e2c02cc443f5c~mv2.png',
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2oty1',
    connectedMobilePresetID: 'variants-lcx2tw18',
    src: 'https://static.wixstatic.com/media/8bb438_5f5872c92cb841bf937b6bcd05bd880c~mv2.png',
    layout: {
      width: '114px',
      height: '141px',
    },
  },
  {
    id: 'externalPreset-variants-lcx39wso',
    connectedMobilePresetID: 'variants-lcx3b4cc',
    src: 'https://static.wixstatic.com/media/8bb438_006b87772ab24906870b165ea1f3d447~mv2.png',
    layout: {
      width: '114px',
      height: '141px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2mz9w',
    connectedMobilePresetID: 'variants-lcx2nvz6',
    src: 'https://static.wixstatic.com/media/8bb438_485470728572485299c7594240f9b4ce~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2vjv5',
    connectedMobilePresetID: 'variants-lcx2wl9q',
    src: 'https://static.wixstatic.com/media/8bb438_2ec581c3e6f44c7b873b98fc9d5319f0~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3e613',
    connectedMobilePresetID: 'variants-lcx3fh5u',
    src: 'https://static.wixstatic.com/media/8bb438_b9fe63fc62e944ea8740ef9dca2f0f38~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2xpur',
    connectedMobilePresetID: 'variants-lcx2ysz2',
    src: 'https://static.wixstatic.com/media/8bb438_5268c00586fb4cf1bcd73f442c59cdcc~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx49otg',
    connectedMobilePresetID: 'variants-lcx4czek',
    src: 'https://static.wixstatic.com/media/8bb438_370fdb1b3cb545249631d1cd59ca0921~mv2.png',
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3wkjh',
    connectedMobilePresetID: 'variants-lcx3xv4j',
    src: 'https://static.wixstatic.com/media/8bb438_6be8fb2855984c2e9e74476f9bb68617~mv2.png',
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3tyba',
    connectedMobilePresetID: 'variants-lcx3va3d',
    src: 'https://static.wixstatic.com/media/8bb438_93121268b6594e0f935087b224228e57~mv2.png',
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lcx866hl',
    connectedMobilePresetID: 'variants-lcx87sv9',
    src: 'https://static.wixstatic.com/media/8bb438_5cee4eb76372472e90425f886a065912~mv2.png',
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lcx7zdj7',
    connectedMobilePresetID: 'variants-lcx82cdm',
    src: 'https://static.wixstatic.com/media/8bb438_cf8cf8f9157849e2a2ff745ad6ae112d~mv2.png',
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lcx46ocb',
    connectedMobilePresetID: 'variants-lcx48bby',
    src: 'https://static.wixstatic.com/media/8bb438_0c202d36851248a795e91bff65c5709a~mv2.png',
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lcx37ssj',
    connectedMobilePresetID: 'variants-lcx38p21',
    src: 'https://static.wixstatic.com/media/8bb438_029a46ca989b4c1c9a4e11c601db60d2~mv2.png',
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lcx30vzu',
    connectedMobilePresetID: 'variants-lcx31wi4',
    src: 'https://static.wixstatic.com/media/8bb438_84846b45663146ebb70a95188d8cb8d7~mv2.png',
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3zthz',
    connectedMobilePresetID: 'variants-lcx416ar',
    src: 'https://static.wixstatic.com/media/8bb438_d83370a8cddf4dc49f5c0f3dad5e1611~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3gquk',
    connectedMobilePresetID: 'variants-lcx3hufz',
    src: 'https://static.wixstatic.com/media/8bb438_b4ec77ca93ae44399d6e90731941385b~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx4hg7w',
    connectedMobilePresetID: 'variants-lcx4j4bw',
    src: 'https://static.wixstatic.com/media/8bb438_da2e19d308f64a59a1007ca5cf1a770d~mv2.png',
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3j63z',
    connectedMobilePresetID: 'variants-lcx3k8i4',
    src: 'https://static.wixstatic.com/media/8bb438_1de9c762e0954c4d986351b6637a38d2~mv2.png',
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3lp4j',
    connectedMobilePresetID: 'variants-lcx3mydl',
    src: 'https://static.wixstatic.com/media/8bb438_21d00cb8bb444e94a0fc739f61999153~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3o6hc',
    connectedMobilePresetID: 'variants-lcx3pog9',
    src: 'https://static.wixstatic.com/media/8bb438_46f9bc114dec46b2b62d0f58394e7701~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
];
