import {
  createBlocksModel,
} from '@wix/yoshi-flow-editor/blocks';
import { ProjectDetailsWidgetData } from './config/types';

const DEFAULT_DATA = {
  projectDetails: [],
} as ProjectDetailsWidgetData;

export default createBlocksModel({
  widgetName: 'ProjectDetailsWidget',
  props: {
    // Here you can define your widget props
    data: {
      type: {} as any,
      title: 'Project Details Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for Project Details Widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
