import model from './model';
import {
  NavigationWidgetComponentIds as ComponentIds,
  NavigationWidgetEvents as Events,
} from './config/constants';
import { NavigationWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    nextButtonWidget: $w(ComponentIds.NextButtonWidget),
    backButtonWidget: $w(ComponentIds.BackButtonWidget),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    data.hideBackButton
      ? components?.backButtonWidget?.hide()
      : components?.backButtonWidget?.show();

    data.hideNextButton
      ? components?.nextButtonWidget?.hide()
      : components?.nextButtonWidget?.show();
  };

  // function that returns the button element link
  const getInnerButtonLink = (component) => {
    if (component?.type === '$w.Button') {
      return component?.link;
    } else if (component?.children[0]) {
      return getInnerButtonLink(component.children[0]);
    } else {
      return '';
    }
  };

  const initEventHandlers = (forceDynamicNavigation) => {
    if (
      forceDynamicNavigation ||
      (!components.backButtonWidget.hidden &&
        getInnerButtonLink(components.backButtonWidget) === '')
    ) {
      components.backButtonWidget?.onNavigationButtonClicked(() => {
        $widget.fireEvent(Events.backClicked, {});
      });
    }

    if (
      forceDynamicNavigation ||
      (!components.nextButtonWidget.hidden &&
        getInnerButtonLink(components.nextButtonWidget) === '')
    ) {
      components.nextButtonWidget?.onNavigationButtonClicked(() => {
        $widget.fireEvent(Events.nextClicked, {});
      });
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    initByProps(nextProps);
    initEventHandlers(nextProps?.data?.forceDynamicNavigation);
  });

  return {
    pageReady: async () => {},
    exports: {},
  };
});
