import model from './model';
import {
  CollectionMSBWidgetComponentIds as ComponentIds,
  CollectionMSBWidgetStates as States,
} from './config/constants';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { CollectionsMSBWidgetWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isEditor } = flowAPI.environment;
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiState),
    galleryWidget: $w(ComponentIds.GalleryWidget) as any,
    collectionWidget: $w(ComponentIds.CollectionWidget),
    emptyStateWidget: $w(ComponentIds.CollectionEmptyStateWidget),
  });

  const initMSB = async (
    collections: Collection[],
    collectionProjects?: Project[],
  ) => {
    switch (collections.length) {
      case 0:
        await components.multiStateBox.changeState(States.EmptyState);
        if (!isEditor) {
          components.emptyStateWidget?.collapse();
        }
        break;
      case 1:
        await components.multiStateBox.changeState(States.Collection);
        components.collectionWidget.data = {
          collection: collections[0],
          projects: collectionProjects,
        };
        break;
      default:
        await components.multiStateBox.changeState(States.Gallery);
        components.galleryWidget.data = { collections };
        break;
    }
  };

  $widget.onPropsChanged(async (_, nextProps) => {
    if (nextProps.data.collections) {
      components = getComponents();
      await initMSB(
        nextProps.data.collections,
        nextProps.data.collectionProjects,
      );
    }
  });

  return {
    pageReady: async () => {},
    exports: {},
  };
});
