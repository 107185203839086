export enum NavigationButtonWidgetComponentIds {
  Root = '#navigationRoot',
  ButtonBox = '#buttonBox',
  TextButton = '#textButton',
  ArrowButton = '#arrowButton',
}

export enum NavigationButtonWidgetEvents {
  Clicked = 'navigationButtonClicked',
}
