import { Logger } from '@wix/yoshi-flow-editor/bi';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import { portfolioPageViewed as portfolioPageViewedLiveSite } from '@wix/bi-logger-portfolio-users-of-users/v2';
import { getPageViewMode } from './viewerUtils';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface IReportPageViewed {
  biLogger: Logger;
  pageName: string;
  flowAPI: PlatformControllerFlowAPI;
}

export const reportPageViewed = ({
  flowAPI,
  biLogger,
  pageName,
}: IReportPageViewed): void => {
  const { isViewer } = flowAPI.environment;
  if (isViewer) {
    biLogger.report(
      portfolioPageViewedLiveSite({
        pageName,
        viewMode: getPageViewMode(flowAPI),
      }),
    );
  } else {
    biLogger.report(
      portfolioPageViewed({
        pageName,
        portfolioViewMode: getPageViewMode(flowAPI),
      }),
    );
  }
};
