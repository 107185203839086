import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { NavigationButtonWidgetData } from './config/types';
import { NavigationButtonWidgetEvents as Events } from './config/constants';

const DEFAULT_DATA = {} as NavigationButtonWidgetData;

export default createBlocksModel({
  widgetName: 'NavigationButtonWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Navigation Button Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for navigation button widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    [Events.Clicked]: {
      description: 'Fired when button clicked',
    },
  },
});
