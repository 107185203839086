import { HttpClient, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { createHeaders, WixHeadersOpts } from '@wix/headers';

export const richTextFormatter = (html: string = '') => {
  const tags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'ul', 'ol'];
  const formattedHtml = tags.reduce((prev = '', tag) => {
    const tempString = prev
      ?.toString()
      ?.replaceAll?.(
        `<${tag}>`,
        `<${tag} style="color: inherit;font-family: inherit;font-size: inherit;text-align: inherit;letter-spacing: inherit;line-height: inherit;">`,
      )
      ?.toString();
    return tempString;
  }, html || '');
  return formattedHtml;
};

export const getPageViewMode = (flowAPI: PlatformControllerFlowAPI) => {
  let viewMode = '';
  const { isViewer, isEditor, isPreview } = flowAPI.environment;
  if (isViewer) {
    viewMode = 'liveSite';
  } else if (isEditor) {
    viewMode = 'editor';
  } else if (isPreview) {
    viewMode = 'preview';
  }

  return viewMode;
};

export const getCustomViewerHttpClient = (
  flowAPI: PlatformControllerFlowAPI,
) => {
  const { multilingual } = flowAPI.environment;
  const headersDraft: WixHeadersOpts = {
    signedInstance: flowAPI.controllerConfig.appParams.instance,
  };
  if (multilingual?.isEnabled) {
    const siteLanguage = multilingual.siteLanguages.find(
      (x) =>
        x.languageCode === flowAPI.environment.multilingual?.currentLanguage,
    );
    headersDraft.multilingualOptions = {
      lang: siteLanguage?.languageCode,
      locale: siteLanguage?.locale,
      isPrimaryLanguage: !!siteLanguage?.isPrimaryLanguage,
    };
  }
  const headers = createHeaders(headersDraft);
  const httpClient = new HttpClient({ headers });
  return { httpClient };
};
