export enum NavigationWidgetComponentIds {
  Root = '#navigationRoot',
  NextButtonWidget = '#nextButtonWidget',
  BackButtonWidget = '#backButtonWidget',
}

export enum NavigationWidgetEvents {
  nextClicked = 'nextClicked',
  backClicked = 'backClicked',
}
// Change = 'change',
