export enum CollectionMSBWidgetComponentIds {
  Root = '#collectionMSBWidgetRoot',
  MultiState = '#collectionMultiStateBox',
  GalleryWidget = '#collectionsGalleryWidget',
  CollectionWidget = '#collectionWidget',
  CollectionEmptyStateWidget = '#collectionEmptyStateWidget1',
}

export enum CollectionMSBWidgetStates {
  Gallery = 'galleryState',
  Collection = 'collectionState',
  EmptyState = 'emptyState',
}

export enum CollectionMSBWidgetWidgetEvents {}
