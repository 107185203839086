import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { CollectionWidgetData } from './config/types';
import { CollectionWidgetEvents as Events } from './config/constants';

const DEFAULT_DATA = {
  collection: {},
  projects: [],
} as CollectionWidgetData;

export default createBlocksModel({
  widgetName: 'CollectionWidget',
  props: {
    // Here you can define your widget props
    data: {
      type: {} as any,
      title: 'Collection Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for collection widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
