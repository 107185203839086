import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

const DEFAULT_DATA = {};

export default createBlocksModel({
  widgetName: 'ProjectPageWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Project Page Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for project page',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
