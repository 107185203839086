export enum CollectionWidgetComponentIds {
  Root = '#collectionWidgetRoot',
  Title = '#collectionTitle',
  Description = '#collectionDescription',
  Gallery = '#collectionGallery',
}

export enum CollectionWidgetEvents {}

export const CollectionWidgetTextComponentIds = [
  CollectionWidgetComponentIds.Title,
  CollectionWidgetComponentIds.Description,
];
