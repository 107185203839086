import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';

export enum CollectionPageWidgetComponentIds {
  Root = '#collectionPageWidgetRoot',
  CollectionWidget = '#collectionWidget',
  NavigationWidget = '#navigationWidget',
  EmptyStateWidget = '#collectionEmptyStateWidget1',
}
export enum CollectionPageWidgetEvents {}

export const CollectionPageWidgetTabsArray: widgetDesignTabsBuilderArray = [
  {
    label: 'Collection_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Collection_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Collection_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Collection_Design_Panel_Back_Button',
    roles: ['#navigationButton'],
    skipOnEditorX: true,
  },
  {
    label: 'Collection_Design_Panel_Page_Background',
    roles: [CollectionPageWidgetComponentIds.Root],
  },
];

export const CollectionPageWidgetPresets: WidgetPresetsArray = [
  {
    id: 'externalPreset-variants-lbxcjmbu',
    connectedMobilePresetID: 'variants-lbxcklxe',
    src: 'https://static.wixstatic.com/media/8bb438_e835ecc504214e77982a57b8e4789b22~mv2.png',
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5hrjm',
    connectedMobilePresetID: 'variants-lbc5mkrs',
    src: 'https://static.wixstatic.com/media/8bb438_5f28cc80588f4d5082b3da15d8e258ca~mv2.png',
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8zgd8',
    connectedMobilePresetID: 'variants-lbc90m76',
    src: 'https://static.wixstatic.com/media/8bb438_99d0e73dafd9497e87a8352691fb6765~mv2.png',
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5obj1',
    connectedMobilePresetID: 'variants-lbc5su2o',
    src: 'https://static.wixstatic.com/media/8bb438_1bdb975eb3b1420ea01e2c02cc443f5c~mv2.png',
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5xfvq',
    connectedMobilePresetID: 'variants-lbc5xb9y',
    src: 'https://static.wixstatic.com/media/8bb438_5f5872c92cb841bf937b6bcd05bd880c~mv2.png',
    layout: {
      width: '114px',
      height: '141px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8143t',
    connectedMobilePresetID: 'variants-lbc81zj8',
    src: 'https://static.wixstatic.com/media/8bb438_006b87772ab24906870b165ea1f3d447~mv2.png',
    layout: {
      width: '114px',
      height: '141px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5v6mw',
    connectedMobilePresetID: 'variants-lbc5v1iy',
    src: 'https://static.wixstatic.com/media/8bb438_485470728572485299c7594240f9b4ce~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5z9yi',
    connectedMobilePresetID: 'variants-lbc5z4w7',
    src: 'https://static.wixstatic.com/media/8bb438_2ec581c3e6f44c7b873b98fc9d5319f0~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc82x7k',
    connectedMobilePresetID: 'variants-lbc83tfx',
    src: 'https://static.wixstatic.com/media/8bb438_b9fe63fc62e944ea8740ef9dca2f0f38~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc64w40',
    connectedMobilePresetID: 'variants-lbc64nay',
    src: 'https://static.wixstatic.com/media/8bb438_5268c00586fb4cf1bcd73f442c59cdcc~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8x3im',
    connectedMobilePresetID: 'variants-lbc8yc96',
    src: 'https://static.wixstatic.com/media/8bb438_370fdb1b3cb545249631d1cd59ca0921~mv2.png',
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8m4re',
    connectedMobilePresetID: 'variants-lbc8n8zv',
    src: 'https://static.wixstatic.com/media/8bb438_6be8fb2855984c2e9e74476f9bb68617~mv2.png',
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8iiiz',
    connectedMobilePresetID: 'variants-lbc8l4ze',
    src: 'https://static.wixstatic.com/media/8bb438_93121268b6594e0f935087b224228e57~mv2.png',
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lbxc0siz',
    connectedMobilePresetID: 'variants-lbxc1tvv',
    src: 'https://static.wixstatic.com/media/8bb438_5cee4eb76372472e90425f886a065912~mv2.png',
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lbxbxc2r',
    connectedMobilePresetID: 'variants-lbxby647',
    src: 'https://static.wixstatic.com/media/8bb438_cf8cf8f9157849e2a2ff745ad6ae112d~mv2.png',
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8ubcd',
    connectedMobilePresetID: 'variants-lbc8w03b',
    src: 'https://static.wixstatic.com/media/8bb438_0c202d36851248a795e91bff65c5709a~mv2.png',
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lbc7z56y',
    connectedMobilePresetID: 'variants-lbc805kh',
    src: 'https://static.wixstatic.com/media/8bb438_029a46ca989b4c1c9a4e11c601db60d2~mv2.png',
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lbc685rw',
    connectedMobilePresetID: 'variants-lbc67zat',
    src: 'https://static.wixstatic.com/media/8bb438_84846b45663146ebb70a95188d8cb8d7~mv2.png',
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8o9d8',
    connectedMobilePresetID: 'variants-lbc8tcmx',
    src: 'https://static.wixstatic.com/media/8bb438_d83370a8cddf4dc49f5c0f3dad5e1611~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc84yzn',
    connectedMobilePresetID: 'variants-lbc896eb',
    src: 'https://static.wixstatic.com/media/8bb438_b4ec77ca93ae44399d6e90731941385b~mv2.png',
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc91ubo',
    connectedMobilePresetID: 'variants-lbc92ymt',
    src: 'https://static.wixstatic.com/media/8bb438_da2e19d308f64a59a1007ca5cf1a770d~mv2.png',
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8a4c1',
    connectedMobilePresetID: 'variants-lbc8axec',
    src: 'https://static.wixstatic.com/media/8bb438_1de9c762e0954c4d986351b6637a38d2~mv2.png',
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8buq0',
    connectedMobilePresetID: 'variants-lbc8epqe',
    src: 'https://static.wixstatic.com/media/8bb438_21d00cb8bb444e94a0fc739f61999153~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8gbqm',
    connectedMobilePresetID: 'variants-lbc8hfa8',
    src: 'https://static.wixstatic.com/media/8bb438_46f9bc114dec46b2b62d0f58394e7701~mv2.png',
    layout: {
      width: '114px',
      height: '98px',
    },
  },
];
