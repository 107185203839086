import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { ProjectWidgetEvents as Events } from './config/constants';
import { ProjectWidgetData } from './config/types';

const DEFAULT_DATA = {
  project: {},
  itemsData: {
    items: [],
    itemsPagingData: {},
  },
  nextProjectSlug: '',
  prevProjectSlug: '',
} as ProjectWidgetData;

export default createBlocksModel({
  widgetName: 'ProjectWidget',
  props: {
    data: {
      type: {} as any,
      title: 'Project Page Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for project page',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
