import model from './model';
import { CollectionsGalleryWidgetComponentIds as ComponentIds } from './config/constants';
import { CollectionsGalleryWidgetProps as Props } from './config/types';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import { getCollectionUrl } from '../../utils/urlsUtils';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const { isSSR } = flowAPI.environment;
  const shouldShowEmptyComponent = isSSR;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    galleryWidget: $w(ComponentIds.GalleyWidget),
  });

  const getWixCodePGItems = (collections: Collection[]) => {
    const collectionCoverImages = collections.map((collection: Collection) => {
      const item: Item & { collectionSlug: string } = {
        image: collection.coverImage,
        title: collection?.title,
        description: collection?.description,
        collectionSlug: collection.slug || '',
        link: {
          url: getCollectionUrl(flowAPI, collection?.slug),
          target: '_self',
        },
      };
      return item;
    });
    return commonImagesToWixCode(collectionCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.collections.length !== 0) {
      components.galleryWidget.items = getWixCodePGItems(data.collections);

      components.galleryWidget.manualStyleParams = {
        behaviourParams_item_clickAction: 'LINK',
      };
    } else if (shouldShowEmptyComponent) {
      components.galleryWidget.items = [];
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    initByProps(nextProps);
  });

  return {
    pageReady: async () => { },
    exports: {},
  };
});
